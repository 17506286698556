export const markTableOfContentsByCurrent = (key: string, target: any): any => {
  const [year, month, day] = key.split('-')
  if (target?.title === year || target?.title === `${year}-${month}` || target?.title === `${year}-${month}-${day}`) {
    target.current = true
  }

  if (target?.children) {
    target.children = target.children.map((child: any) => markTableOfContentsByCurrent(key, child))
  }
  return target
}

export const markTableOfContentsUpToRoot = (target: any): any => {
  // NOTE: 3階層前提のロジックのため、3階層以上の階層構造は対応していない
  if (!target?.root && target?.children?.some((child: any) => child.current || child.children?.some((child: any) => child.current))) {
    target.current = true
  }

  if (target?.children) {
    target.children = target.children.map((child: any) => markTableOfContentsUpToRoot(child))
  }
  return target
}
