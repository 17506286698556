import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-90ef604e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "addon-view-index" }
const _hoisted_2 = { class: "is-sr-only" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "addon-view-index-root" }
const _hoisted_5 = { id: "addon-view-index-root" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ItemTreeIndex = _resolveComponent("ItemTreeIndex")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('rightPane.index')), 1),
    (_ctx.contentTreeIndex.children)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_router_link, {
              to: `/${_ctx.makeRootUrl(_ctx.contentTreeIndex)}`
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.contentTreeIndex.title), 1)
              ]),
              _: 1
            }, 8, ["to"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentTreeIndex.children, (item) => {
              return (_openBlock(), _createBlock(_component_ItemTreeIndex, {
                "top-pid-number": _ctx.contentTreeIndex.root,
                key: item.pid,
                item: item,
                show: false,
                viewerType: _ctx.viewerType,
                tag: "div"
              }, null, 8, ["top-pid-number", "item", "viewerType"]))
            }), 128))
          ])
        ]))
      : (_openBlock(), _createBlock(_component_ItemTreeIndex, {
          key: 1,
          item: _ctx.contentTreeIndex,
          children: _ctx.contentTreeIndex.children,
          show: true,
          viewerType: _ctx.viewerType,
          tag: "div"
        }, null, 8, ["item", "children", "viewerType"]))
  ]))
}